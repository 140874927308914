import type { JSX } from "solid-js";
import { createSignal, createUniqueId, For, onMount, Show } from "solid-js";
import type { InputTypeTextProps } from "~/components/Forms/Fields/Base/InputTypesProps";
import { useFormContext } from "~/contexts/FormContext";
import { debounce } from "@solid-primitives/scheduled";
import IconExpandMore from "~/img/icons/expand_more.svg";

type Suggestion = {
  id: string;
  name: string;
  is_city: boolean;
  is_department: boolean;
  is_region?: boolean;
  context: string;
  departement: string;
  postal_code: string;
  lng: number;
  lat: number;
};

const [suggestions, setSuggestions] = createSignal<Suggestion[]>([]);

export type InputTypeLocationAutoCompleteProps = InputTypeTextProps & {
  placesScope?: string;
  fieldNamePrefix?: string;
  valueCity?: string;
  valueDepartement?: string;
  valueRegion?: string;
  valuePostalCode?: string;
  valueCountry?: string;
  valueLat?: number;
  valueLng?: number;
};

export function InputTypeLocationAutoComplete(
  props: InputTypeLocationAutoCompleteProps,
) {
  const [formStore, { setValue: formSetValue }] = useFormContext();
  const setValue = props.defaultFormStorage
    ? props.defaultFormStorage
    : formSetValue;

  const isRequired = () => props.required || false;
  const size = () => props.size || 60;
  const maxlength = () => props.maxLength || 128;
  // eslint-disable-next-line solid/reactivity
  const [inputHasContent, setInputHasContent] = createSignal(!!props.value);
  const [inputIsFocus, setInputIsFocus] = createSignal(false);
  const [touched, setTouched] = createSignal(false);
  const [blurCount, setBlurCount] = createSignal(0);

  // We use a prefix in case we have multiple location fields in the same form
  const prefix = () => props.fieldNamePrefix || "";

  const setHasContent: JSX.EventHandler<HTMLInputElement, Event> = (e) => {
    setInputHasContent(e.currentTarget.value.length > 0);
  };

  onMount(() => {
    props.validateFn && props.value && props.validateFn(props.value);
    setInputHasContent(!!props.value);
  });

  let input!: HTMLInputElement;
  // eslint-disable-next-line solid/reactivity
  const id = `${props.name}-${createUniqueId()}`;

  const clearAutoCompleteFields = () => {
    setValue!(`${prefix()}city`, "");
    setValue!(`${prefix()}department`, undefined);
    setValue!(`${prefix()}region`, undefined);
    setValue!(`${prefix()}postal_code`, undefined);
    setValue!(`${prefix()}country`, undefined);
    setValue!(`${prefix()}lat`, undefined);
    setValue!(`${prefix()}lng`, undefined);
  };

  const setSuggestion = (suggestion: Suggestion) => {
    input.value = suggestion.name;
    setValue!(`${prefix()}city`, suggestion.is_city ? suggestion.name : "");
    setValue!(
      `${prefix()}department`,
      suggestion.is_department
        ? suggestion.name
        : (suggestion.departement ?? ""),
    );
    setValue!(`${prefix()}region`, suggestion.is_region ? suggestion.name : "");
    setValue!(`${prefix()}postal_code`, suggestion.postal_code ?? "");
    setValue!(`${prefix()}country`, "France");
    setValue!(`${prefix()}lat`, suggestion.lat ? String(suggestion.lat) : "");
    setValue!(`${prefix()}lng`, suggestion.lng ? String(suggestion.lng) : "");
  };

  const [menuDialogIsActive, setMenuDialogIsActive] = createSignal(false);

  return (
    <>
      <div
        class={`form-control form-control-${props.name}`}
        classList={{
          "is-focus": inputIsFocus(),
          "has-content": inputHasContent(),
          "in-error": props["aria-invalid"],
          "is-disabled": props.disabled,
        }}
      >
        <div class="form-slot form-text-field form-location">
          <Show when={props.label} keyed>
            <label for={id}>
              {props.label}
              <Show when={isRequired()} keyed>
                {" "}
                *
              </Show>
            </label>
          </Show>

          <input
            ref={input}
            type="text"
            id={id}
            name={props.name}
            data-test={props.name}
            size={size()}
            maxlength={maxlength()}
            required={isRequired()}
            value={props.value || ""}
            placeholder={props.placeholder}
            autocomplete="off"
            aria-required={isRequired()}
            aria-invalid={props["aria-invalid"] || false}
            aria-errormessage={
              props["aria-invalid"] ? props["aria-errormessage"] : undefined
            }
            onFocusIn={() => setInputIsFocus(true)}
            onFocusOut={() => setInputIsFocus(false)}
            onChange={(event) => setHasContent(event)}
            onFocus={(event) => {
              !touched() && setTouched(true);
              props.onFocus && props.onFocus(event);
              getSuggestionsDebounced(event.currentTarget.value);
              setMenuDialogIsActive(true);
            }}
            onInput={(event) => {
              // Perform a search based on the input value
              getSuggestionsDebounced(event.currentTarget.value);
              if (props.onInput) {
                props.onInput(event);
              }
              if (props.validateFn && touched() && blurCount() > 0) {
                props.validateFn(event.currentTarget.value);
              }
              setValue!(props.name, event.currentTarget.value);
              setMenuDialogIsActive(true);
            }}
            onBlur={(event) => {
              setBlurCount((prev) => prev + 1);
              if (props.onBlur) {
                props.onBlur(event);
              }
              if (props.validateFn && touched()) {
                props.validateFn(event.currentTarget.value);
              }
            }}
            onKeyUp={() => {
              clearAutoCompleteFields();
            }}
          />
          <i aria-hidden="true" class="cog-icon">
            <IconExpandMore />
          </i>

          <div
            class="menu-dialog menu-dialog-autocomplete"
            classList={{
              active: suggestions().length > 0 && menuDialogIsActive(),
            }}
          >
            <div
              class="dialog-overlay"
              onClick={() => setMenuDialogIsActive(false)}
            />
            <ul class="list">
              <For each={suggestions()}>
                {(suggestion) => (
                  <li classList={{ active: suggestion.name === input.value }}>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setSuggestion(suggestion);
                        setMenuDialogIsActive(false);
                        return false;
                      }}
                    >
                      {suggestion.name}
                      <span class="autocomplete-context">
                        {suggestion.context}
                      </span>
                    </a>
                  </li>
                )}
              </For>
            </ul>
          </div>

          <input
            type="hidden"
            name={`${prefix()}city`}
            data-test={`${prefix()}city`}
            value={props.valueCity || formStore.values[`${prefix()}city`] || ""}
          />
          <input
            type="hidden"
            name={`${prefix()}department`}
            data-test={`${prefix()}department`}
            value={
              props.valueDepartement ||
              formStore.values[`${prefix()}departement`] ||
              ""
            }
          />
          <input
            type="hidden"
            name={`${prefix()}region`}
            data-test={`${prefix()}region`}
            value={
              props.valueRegion || formStore.values[`${prefix()}region`] || ""
            }
          />
          <input
            type="hidden"
            name={`${prefix()}postal_code`}
            data-test={`${prefix()}postal_code`}
            value={
              props.valuePostalCode ||
              formStore.values[`${prefix()}postal_code`] ||
              ""
            }
          />
          <input
            type="hidden"
            name={`${prefix()}country`}
            data-test={`${prefix()}country`}
            value={
              props.valueCountry || formStore.values[`${prefix()}country`] || ""
            }
          />
          <input
            type="hidden"
            name={`${prefix()}lat`}
            data-test={`${prefix()}lat`}
            value={props.valueLat || formStore.values[`${prefix()}lat`] || ""}
          />
          <input
            type="hidden"
            name={`${prefix()}lng`}
            data-test={`${prefix()}lng`}
            value={props.valueLng || formStore.values[`${prefix()}lng`] || ""}
          />
        </div>
        <Show when={props.help} keyed>
          <div class="field-help">{props.help}</div>
        </Show>
      </div>
    </>
  );
}

const getSuggestionsDebounced = debounce((value: string) => {
  getSuggestions(value).then((res) => {
    setSuggestions(res);
  });
}, 500);

/**
 * Search for suggestions based on the input value directly from the backend
 *
 * @param value search query
 * @returns Suggestion[]
 */
async function getSuggestions(value: string) {
  "use server";

  const config = {
    method: "GET",
    headers: new Headers(),
  };

  const url =
    import.meta.env.VITE_COG_BACKEND_BASE_URL +
    "/geo-complete/?search_text=" +
    value;

  const request = new Request(url, config);

  // Lazily load input items
  return fetch(request)
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}
